<template>
  <section>
    <div class="head">
      <div class="head__detail">
        <div class="head__logo">
          <div class="head__logo--left">
            <img src="../assets/LOGO1.svg" alt="" />
          </div>
        </div>
        <div class="head__nav">
          <div style="visibility: hidden" class="head__nav__item" @click="material">
            素材
            <div class="foot__line" ref="material"></div>
          </div>
          <div style="visibility: hidden" class="head__nav__item" @click="plugIn">
            插件
            <div class="foot__line" ref="plugIn"></div>
          </div>
          <div class="head__nav__item" @click="manpower" style="display: none">
            人力
            <div class="foot__line" ref="manpower"></div>
          </div>
          <div style="visibility: hidden" class="head__nav__item" @click="contactUs">
            联系我们
            <div class="foot__line" ref="contactUs"></div>
          </div>

          <div class="head__nav__items" v-if="!userId">
            <span @click="goLogin">注册</span
            >&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;<span @click="goLogin"
              >登录</span
            >
          </div>
          <div class="head__nav__items--login" v-else>
            <div class="my-detail" @click="goMyDetail">
              <img
                :src="showImg"
                alt=""
                width="40"
                height="40"
                style="border-radius: 50%"
                v-if="showImg"
              />
              <img
                src="../assets/test-man.svg"
                alt=""
                width="40"
                height="40"
                style="border-radius: 50%"
                v-else
              />
              &nbsp;<span class="nickName ignore">{{nickName}}</span>
            </div>
            <div class="head__nav__items__card ignore">
              <div v-if="!isEnglish" class="card__item" @click="languageChange('english')">
                切换到英文
              </div>
              <div v-if="isEnglish" class="card__item" @click="languageChange('chinese_simplified')">
                Switch to Chinese
              </div>
              <div v-if="!isEnglish" class="card__item" style="margin-bottom: 0" @click="loginOut">
                退出登录
              </div>
              <div v-if="isEnglish" class="card__item" style="margin-bottom: 0" @click="loginOut">
                Log Out
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 62px"></div>
  </section>
</template>

<script>
import { imageBaseUrl } from "@/services/HttpService";
let xhr = new XMLHttpRequest();
// import translate  from "@/utils/translate";
export default {
  name: "home-index",
  props: ["goRelation"],
  data() {
    return {
      isAuditor: false,
      userId: localStorage.getItem("userId"),
      showImg: localStorage.getItem("profilePictureUrl"),
      nickName: localStorage.getItem("nickName"),
      isEnglish: false, // 当前语言是否是英文
    };
  },
  watch: {
    "$store.state.routeNavTitle": {
      handler: function (newVal) {
        if (newVal === "素材") {
          this.$refs.material.style.display = "block";
          this.$refs.plugIn.style.display = "none";
          this.$refs.manpower.style.display = "none";
          this.$refs.contactUs.style.display = "none";
        } else if (newVal === "插件") {
          this.$refs.material.style.display = "none";
          this.$refs.plugIn.style.display = "block";
          this.$refs.manpower.style.display = "none";
          this.$refs.contactUs.style.display = "none";
        } else if (newVal === "人力") {
          this.$refs.material.style.display = "none";
          this.$refs.plugIn.style.display = "none";
          this.$refs.manpower.style.display = "block";
          this.$refs.contactUs.style.display = "none";
        } else if (newVal === "联系我们") {
          this.$refs.material.style.display = "none";
          this.$refs.plugIn.style.display = "none";
          this.$refs.manpower.style.display = "none";
          this.$refs.contactUs.style.display = "block";
        }
      },
    },
    deep: true, // 深度监听
    immediate: true, // 第一次初始化渲染就可以监听到
  },
  destroyed() {
    this.$store.state.isMyPage = true;
  },
  created() {},
  mounted() {
    this.isEnglish = translate.language.getCurrent() === 'english';
    if (this.showImg) {
      if (this.showImg.includes("null")) {
        this.showImg = "";
      } else {
        if (!this.showImg.includes("https")) {
          this.showImg = imageBaseUrl + this.showImg;
        }
      }
    }
    const roles = JSON.stringify(localStorage.getItem("roleIds"));
    if (roles.indexOf("AUDITOR") > -1) {
      this.isAuditor = true;
    } else {
      this.isAuditor = false;
    }
    this.$store.state.routeNavTitle = this.$store.state.routeNavTitle
      ? this.$store.state.routeNavTitle
      : "素材";
    if (this.$store.state.routeNavTitle === "素材") {
      this.$refs.material.style.display = "block";
      this.$refs.plugIn.style.display = "none";
      this.$refs.manpower.style.display = "none";
      this.$refs.contactUs.style.display = "none";
    } else if (this.$store.state.routeNavTitle === "插件") {
      this.$refs.material.style.display = "none";
      this.$refs.plugIn.style.display = "block";
      this.$refs.manpower.style.display = "none";
      this.$refs.contactUs.style.display = "none";
    } else if (this.$store.state.routeNavTitle === "人力") {
      this.$refs.material.style.display = "none";
      this.$refs.plugIn.style.display = "none";
      this.$refs.manpower.style.display = "block";
      this.$refs.contactUs.style.display = "none";
    } else if (this.$store.state.routeNavTitle === "联系我们") {
      this.$refs.material.style.display = "none";
      this.$refs.plugIn.style.display = "none";
      this.$refs.manpower.style.display = "none";
      this.$refs.contactUs.style.display = "block";
    }
  },
  methods: {
    // 切换语言
    languageChange(type){
      translate.changeLanguage(type); // 切换语言
      this.isEnglish = type === 'english';
      this.$bus.$emit("languageHasChanged", type)
    },
    material() {
      scroll({
        top: 0,
        behavior: "smooth",
      });
      if (this.$route.name !== "HomeIndex") {
        this.$router.push({
          name: "HomeIndex",
        });
      }
      this.$store.state.routeNavTitle = "素材";
      this.$store.dispatch("openVuex", "素材");
      this.$store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
    },
    plugIn() {
      scroll({
        top: 0,
        behavior: "smooth",
      });
      if (this.$route.name !== "HomeIndex") {
        this.$router.push({
          name: "HomeIndex",
        });
      }
      this.$store.state.routeNavTitle = "插件";
      this.$store.dispatch("openVuex", "插件");
      this.$store.dispatch("checkId", "80b933f37f5a643e2fbadae729fbd67b");
    },
    manpower() {
      scroll({
        top: 0,
        behavior: "smooth",
      });
      if (this.$route.name !== "HomeIndex") {
        this.$router.push({
          name: "HomeIndex",
        });
      }
      this.$store.state.routeNavTitle = "人力";
      this.$store.dispatch("openVuex", "人力");
      this.$store.dispatch("checkId", "nbde79297d70b48f9a7387df87829d42");
    },
    goLogin() {
      this.$store.state.isMyPage = true;
      this.$router.push({
        name: "Login",
      });
    },
    goMyDetail() {
      if (this.isAuditor) {
        this.$router.push({
          name: "StayAudit",
        });
      } else {
        this.$router.push({ name: "UploadResource" });
      }
      this.$store.dispatch("checkSideMenu", "port");
    },
    goMyDetails() {
      if (this.isAuditor) {
        this.$router.push({
          name: "StayAudit",
        });
      } else {
        this.$router.push({ name: "UploadResource" });
      }
      this.$router.push({
        name: "PersonalDetails",
      });
      this.$store.dispatch("checkSideMenu", "edit");
    },
    // 退出登录
    loginOut() {
      const that = this;
      this.$confirm({
        title: "确定要退出登录吗?",
        okText: "确定",
        okType: "primary",
        cancelText: "取消",
        onOk() {
          xhr.open("post","https://utp.veryengine.cn/User/SignOut");
          xhr.withCredentials = true;
          xhr.send();
          xhr.onreadystatechange = function (){
            if (xhr.readyState === 4&& xhr.status === 200){
              let resp = JSON.parse(xhr.response);
              console.log(resp)
              if (resp.code === "00000"){
                that.$message.success("已退出", 1.5)
              }
            }
          }
          localStorage.removeItem("userId");
          localStorage.removeItem("nickName");
          localStorage.removeItem("token");
          that.$store.dispatch("openVuex", "");
          that.$store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
          that.$store.dispatch("checkSideMenu", "edit");
          that.$store.state.isMyPage = true;
          that.$router.push({
            name: "Login",
          });
        },
      });
    },
    contactUs() {
      this.$store.state.isCheckUs = true;
      this.goRelation();
      this.$store.state.routeNavTitle = "联系我们";
      this.$store.dispatch("openVuex", "联系我们");
    },
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  min-width: 900px;
  display: flex;
  justify-content: center;
  min-width: 1185px;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
}
.head__detail {
  width: 74%;
  padding: 9px 4px;
  display: flex;
}
.head__logo {
  display: flex;
}
.head__logo--left img {
  width: 169px;
  height: 54px;
}
.head__logo--right {
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.head__nav {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12%;
}
.head__nav__item {
  flex: 1;
  color: #333333;
  height: 54px;
  line-height: 54px;
  position: relative;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}
.head__nav__items span {
  cursor: pointer;
}
.foot__line {
  position: absolute;
  bottom: 0;
  width: 70px;
  height: 4px;
  opacity: 1;
  background: #0052d9;
  right: 0;
  left: calc(50% - 35px);
  display: none;
}
.head__nav__items {
  color: #333333;
  height: 54px;
  line-height: 54px;
  position: relative;
  flex: 1.5;
  text-align: right;
  white-space: nowrap;
}
.head__nav__items--login {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 54px;
  line-height: 54px;
  position: relative;
  min-width: 138px;
}
.my-detail {
  display: flex;
  align-items: center;
}
.head__nav__items--login span {
  margin: 0 10px;
  cursor: pointer;
}

.nickName {
  color: #0052D9;
  font-size: 14px;
}
.head__nav__items__card {
  width: 138px;
  display: none;
  border-radius: 8px;
  opacity: 1;
  padding: 10px 4px;
  text-align: left;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #DCDCDC;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  position: absolute;
  top: 53px;
  left: 0;
}
.card__item {
  padding-left: 8px;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #333333;
  margin-bottom: 10px;
  text-indent: 0.2em;
}
.card__item:hover {
  background-color: #E3EDFF;
}
.head__nav__items--login:hover .head__nav__items__card {
  display: block;
}

</style>
