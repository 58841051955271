export default [
  // 首页
  {
    path: "/home-index-black",
    name: "homeIndexBlack",
    component: () =>
      import(
        /* webpackChunkName: "HomeIndexBlack" */ "@/views/blackViews/HomeIndexBlack.vue"
      ),
  },
  // 资源详情
  {
    path: "/resource-detail-black/:productId",
    name: "ResourceDetailBlack",
    component: () =>
      import(
        /* webpackChunkName: "ResourceDetailBlack" */ "@/views/blackViews/ResourceDetailBlack"
      ),
  },
  {
    path: "/adm-black",
    name: "AdminBlack",
    component: () =>
      import(
        /* webpackChunkName: "AdminBlack" */ "@/views/blackViews/AdminBlack.vue"
      ),
    children: [
      // 资源管理-上传资源
      {
        path: "upload-resource-black",
        name: "UploadResourceBlack",
        component: () =>
          import(
            /* webpackChunkName: "UploadResourceBlack" */ "@/views/blackViews/UploadResourceBlack"
          ),
      },
      {
        path: "message-black",
        name: "MessageBlack",
        component: () =>
          import(
            /* webpackChunkName: "MessageBlack" */ "@/views/blackViews/MessageBlack"
          ),
      },
      {
        path: "personal-details-black",
        name: "PersonalDetailsBlack",
        component: () =>
          import(
            /* webpackChunkName: "MessageBlack" */ "@/views/blackViews/PersonalDetailsBlack"
          ),
      },
      {
        path: "modify-personal-details-black",
        name: "ModifyPersonalDetailsBlack",
        component: () =>
          import(
            /* webpackChunkName: "MessageBlack" */ "@/views/blackViews/ModifyPersonalDetailsBlack"
          ),
      },
    ],
  },
  // 支付
  {
    path: "/buy-black",
    name: "BuyBlack",
    component: () =>
      import(
        /* webpackChunkName: "BuyBlack" */ "@/views/blackViews/BuyBlack.vue"
      ),
  },
];
