import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import { loginByTrail } from "@/services/LoginService";
import store from "../store/store";
import blackRoutes from "./blackRoutes";

let xhr = new XMLHttpRequest();
//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Login" */ "@/views/Login"),
  },
  // 首页
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      hasBar: true,
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "HomeIndex" */ "@/views/homeIndex"),
  },
  // 资源详情
  {
    path: "/resource-detail/:productId",
    name: "ResourceDetail",
    meta: {
      hasBar: true,
      fatherName: ["HomeIndex"],
    },
    component: () =>
      import(/* webpackChunkName: "ResourceDetail" */ "@/views/ResourceDetail"),
  },
  // 支付
  {
    path: "/payment",
    name: "Payment",
    meta: {
      fatherName: ["HomeIndex"],
    },
    component: () =>
      import(/* webpackChunkName: "Payment" */ "@/views/Payment"),
  },
  {
    path: "/check-product-detail/:id/:type",
    name: "CheckProductDetail",
    component: () =>
      import(/* webpackChunkName: "Payment" */ "@/views/checkProductDetail"),
  },
  {
    path: "/adm",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "Admin" */ "@/views/Admin.vue"),
    children: [
      {
        path: "personal-details",
        name: "PersonalDetails",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ProjectManagement" */ "@/views/PersonalDetails"
          ),
      },
      // 资源管理-上传资源
      {
        path: "upload-resource",
        name: "UploadResource",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "UploadResource" */ "@/views/resource-mgn/UploadResource"
          ),
      },
      {
        path: "tag",
        name: "Tag",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(/* webpackChunkName: "Message" */ "@/views/Tag"),
      },
      {
        path: "modify-personal-details",
        name: "ModifyPersonalDetails",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ModifyPersonalDetails" */ "@/views/modifyPersonalDetail"
          ),
      },
      {
        path: "shopping-car",
        name: "ShoppingCar",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(/* webpackChunkName: "ShoppingCar" */ "@/views/shoppingCar"),
      },
      {
        path: "message",
        name: "Message",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(/* webpackChunkName: "Message" */ "../views/message"),
      },

      // 收藏夹
      {
        path: "favorites",
        name: "Favorites",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(/* webpackChunkName: "Favorites" */ "@/views/Favorites"),
      },
      // 待审核
      {
        path: "stay-audit",
        name: "StayAudit",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(/* webpackChunkName: "StayAudit" */ "@/views/stayAudit"),
      },
      // 已通过
      {
        path: "already-passed",
        name: "AlreadyPassed",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AlreadyPassed" */ "@/views/alreadyPassed"
          ),
      },
      // 已驳回
      {
        path: "already-reject",
        name: "AlreadyReject",
        meta: {
          hasPersonBar: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "AlreadyReject" */ "@/views/alreadyReject"
          ),
      },
    ],
  },
  ...blackRoutes,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "HomeIndex" && to.name !== "ResourceDetail") {
    window.scrollTo(0, 0);
  }

  if (
    to.name === "HomeIndex" ||
    to.name === "ResourceDetail" ||
    to.name === "Login"
  ) {
    NProgress.start();
    next();
  } else {
    xhr.open("post", "https://utp.veryengine.cn/User/HasLogin");
    xhr.withCredentials = true;
    xhr.send();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let resp = JSON.parse(xhr.response);
        if (resp.code === "00000") {
          if (!store.state.isMyPage || !from.name) {
            const data = resp.data;
            const params = {
              nickname: data.userName,
              outId: data.userId,
              wechatId: data.wechat ? data.wechat : "",
              wechatName: data.userName,
              photo: data.avatar,
              email: data.email ? data.email : "",
              mobile: data.phone ? data.phone : "",
            };
            localStorage.setItem("nickName", data.userName);
            if (params) {
              loginByTrail(params)
                .then((resp) => {
                  console.log(resp);
                  if (resp.data.code === "SUCCESS") {
                    const data = resp.data.data;
                    localStorage.setItem("userId", data.userId);
                    localStorage.setItem(
                      "profilePictureUrl",
                      data.profilePictureUrl
                    );
                    localStorage.setItem("roleIds", data.roleIds);
                    localStorage.setItem("token", data.token);
                    store.state.isMyPage = true;
                    console.log(store.state.isMyPage);
                    NProgress.start();
                    next();
                  } else {
                    NProgress.start();
                    next();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              NProgress.start();
              next();
            }
          } else {
            NProgress.start();
            next();
          }
        } else {
          next({
            replace: true,
            name: "Login",
          });
        }
      } else {
        NProgress.start();
        next();
      }
    };
  }
});

router.afterEach(() => {
  NProgress.done();
  // 定时器解决 部分翻译问题
  let num = 0;
  let intervalName = null;
  intervalName = setInterval(() => {
    if (num < 10) {
      translate.execute();
      num++;
    } else {
      clearInterval(intervalName);
    }
  }, 500);
});

export default router;
